import { createSelector } from 'reselect'
import { rootSelectorFactory } from 'chrysalis'
import loginReducer from './reducer'

export const rootSelector = rootSelectorFactory<typeof loginReducer>(__filenamespace)

export const tokenSelector = createSelector(rootSelector, (state) => state.token)

export const loadingSelector = createSelector(rootSelector, (state) => state.loading)

export const userSelector = createSelector(rootSelector, (state) => state.user)

export const routeSelector = createSelector(rootSelector, (state) => state.router)

export const groupsSelector = createSelector(rootSelector, (state) => state.groupsSuccess)
